<template>
    <div class="navbar-view" :style="opacity">
        <div class="navbar flex-center justify-between">
            <div class="left flex-center">
                <div @click="toHome" class="flex-center">
                    <div class="real-bo"></div>
                    <div class="logo"></div>
                </div>
                <div class="icon-r margin-left-xs"></div>
                <el-menu :default-active="activeIndex" router class="el-menu-demo" mode="horizontal">
                    <el-menu-item @click="scrollTo('navbar')">首页</el-menu-item>
                    <el-menu-item @click="scrollTo('canDo')">产品介绍</el-menu-item>
                    <el-menu-item @click="scrollTo('case')">客户案例</el-menu-item>
                    <el-menu-item @click="scrollTo('foot')">获取AI员工</el-menu-item>
                    <el-menu-item index="/about">关于我们</el-menu-item>
                </el-menu>
            </div>
            <div class="margin-right">
                <el-button @click="toScrm" class="concatButton" round>登录</el-button>
                <el-button @click="openDialog" size="small" class="loginButton" round>
                    <span class="text-sm">联系我们</span></el-button>
                <el-button @click="openDrawer" class="openButton">
                    <div class="line"></div>
                </el-button>
            </div>

        </div>
        <el-drawer :visible.sync="drawer" :modal-append-to-body="false" size="80%">
            <div class="drawer-view-navbar padding-left-xl">
                <el-menu :default-active="activeIndex" router class="el-menu-demo">
                    <el-menu-item @click="scrollTo('navbar')">首页</el-menu-item>
                    <el-menu-item @click="scrollTo('canDo')">产品介绍</el-menu-item>
                    <el-menu-item @click="scrollTo('case')">客户案例</el-menu-item>
                    <el-menu-item @click="scrollTo('foot')">获取AI员工</el-menu-item>
                    <el-menu-item index="/about">关于我们</el-menu-item>
                </el-menu>
            </div>
            <el-button @click="openDialog" type="primary" class="more">联系我们</el-button>
        </el-drawer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            opacity: {},
            activeIndex: "/",
            show: true,
            drawer: false,
        }
    },
    methods: {
        openDialog() {
            this.drawer = false;
            this.$emit("openDialog")
        },
        openDrawer() {
            this.drawer = true;
        },
        toScrm() {
            this.visible = false;
            window.open("https://console.real-bot.cn/login");
        },
        scrollTo(ref) {
            console.log(this.$route.name, 'this.$router.name');
            if (this.$route.name != 'index') {
                this.$router.replace({
                    name: "index",
                    query: {
                        ref: ref
                    }
                }).catch(() => { })
                return;
            }
            this.$emit("scrollTo", ref)
        },
        toHome() {
            if (this.$route.name != 'index') {
                this.$router.push({ name: "index" })
            }
        }
    },
    mounted() {
        window.onscroll = e => {
            const opacity = (window.scrollY + 40) / 80;
            this.opacity = opacity > 1 ? { 'background-color': 'rgba(255, 255, 255, 0.92)', 'backdrop-filter': 'blur(10px)' } : { 'background': 'hsla(0,0%,100%,.1)', 'backdrop-filter': 'blur(4px)' };
        }
    },
    watch: {
        '$route': {
            handler: function (to) {
                this.activeIndex = to.path;
            },
            deep: true, // 深度观察监听 设置为 true
            immediate: true, // 第一次初始化渲染就可以监听到
        }
    }
}
</script>

<style lang="scss" scoped>
.navbar-view {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
}

.navbar {
    max-width: 1360px;
    margin: 0 auto;
    height: 64px;
    // box-shadow: 0 2px 8px 0 hsla(0, 0%, 8%, .09);

    &:hover {
        // background-color: #ffffff;
    }

    .el-menu-demo {
        margin-left: 57px;
        border-bottom: 0 none;
        background: transparent;

        &::before,
        &::after {
            display: none;
        }

        &>.el-menu-item {
            font-size: 16px;
            margin-right: 37px;
            color: #6F7C95;
            transition: none;

            &.is-active {
                border-bottom: 0 none;
                color: #3981F8;
            }

            &:not(.is-disabled):focus,
            &:not(.is-disabled):hover {
                background: transparent;
            }
        }

    }

    .real-bo {
        width: 38px;
        height: 32px;
        background: url(https://oss.isuiyou.cn/scrm/dbc1b6f7-d170-46ba-abc5-e5f10b2b40b8/real-bo.png) center center no-repeat;
        background-size: contain;
    }

    .logo {
        width: 101px;
        height: 26px;
        background: url(https://oss.isuiyou.cn/scrm/97741b56-de1e-4b6d-abce-342bec7eb2e9/logo.png) center center no-repeat;
        background-size: contain;
    }

    .icon-r {
        width: 140px;
        height: 26px;
        // margin-top: -15px;
        background: url(https://oss.isuiyou.cn/b1966e4240a8b918acb94ad514089708d82711a23dad7bf33848b21d088fe412.png) center center no-repeat;
        background-size: contain;
    }

    .el-button--primary {
        background: linear-gradient(285deg, #5DA2FE 13%, #3981F8 89%);
    }

    .loginButton {
        border-radius: 218px;



        padding: 0;
        width: 92px;
        height: 34px;
        font-size: 13px;
        color: #FFFFFF;
        background: #121212;
        word-spacing: 0.5px;
    }

    .concatButton {
        width: 92px;
        padding: 9px 25px !important;
    }

    .openButton {
        display: none;
        background: transparent;
        border-color: transparent;

        .line {
            width: 40px;
            height: 4px;
            background-color: #333;
            border-radius: 2px;
            position: relative;

            &::before,
            &::after {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                background-color: #333;
                border-radius: 2px;
            }

            &::before {
                top: -14px;
                left: 0px;
            }

            &::after {
                top: 14px;
                left: 0;
            }
        }
    }


}

.navbar-view ::v-deep {
    .el-dialog__close {
        font-size: 40px;
        color: #060606;
    }

    .drawer-view-navbar {
        margin-top: 30%;

        .el-menu-demo {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-content: space-between;
            justify-content: space-evenly;
            align-items: flex-start;
            margin-left: 60px;
        }

        li {
            font-size: 30px !important;
            margin-bottom: 80px;
        }

        .el-menu-item {
            font-size: 16px;
            margin-right: 32px;
            color: #3D3D3D;
            transition: none;
            padding-left: 15px !important;

            &.is-active {
                margin-left: 15px;
                color: #3981F8;
                border-bottom: 2px solid #3981F8;
            }
        }
    }

    .more {
        width: 400px;
        height: 80px;
        border-radius: 80px;
        font-size: 30px;
        display: block;
        position: absolute;
        bottom: 200px;
        left: 50%;
        margin-left: -200px;
        background-color: #3981F8;
    }
}
</style>