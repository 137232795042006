<template>
  <el-popover placement="right-end" width="220" trigger="hover">
    <div class="text-xs text-center">近 200+企业都在用的AI数智员工</div>
    <div class="padding-xs padding-bottom-xs">
      <el-image :src="qrCode"></el-image>
      <div class="text-center margin-top-sm">扫码获取企业服务</div>
    </div>
    <el-button class="weChatBtn" slot="reference">
      <i class="icon-wechat"></i>
    </el-button>
  </el-popover>
</template>

<script>
import qrCode from '@/assets/48@2x.png'
export default {
  data() {
    return {
      qrCode,
    }
  },
}
</script>

<style lang="scss" scoped>
.weChatBtn {
  position: fixed;
  right: 60px;
  bottom: 100px;
  width: 83px;
  height: 83px;
  background: linear-gradient(134deg, #7901E3 26%, #D90024 82%);
  border-radius: 50%;
  border-color: transparent;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  .icon-wechat {
    width: 40px;
    height: 40px;
    display: block;
    background: url(https://oss.isuiyou.cn/e0fcd9c98013cd46ef00c7b9f567df0bb54744bf758142072196a24eefd43273.png) center center no-repeat;
    background-size: contain;
  }
}
</style>